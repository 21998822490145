<template>
  <div class="mocha" ref="mocha">
    <div class="function-buttons" ref="functionButtons">
      <!-- <el-button
        @click="getXLS"
        type="primary"
        size="small"
        :disabled="tableData.length === 0"
        >导出</el-button
      > -->
      <el-button type="primary" size="small" @click="remindUpdate"
        >提醒用户系统已更新</el-button
      >
    </div>
    <div class="form-area mocha-form" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        :rules="formRules"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="数据库源：" prop="dataType">
          <el-select
            v-model="form.dataType"
            clearable
            placeholder="请选择数据库源"
            @change="getSubs"
          >
            <el-option
              v-for="item in modelList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据库：" prop="database">
          <el-select
            v-model="form.database"
            clearable
            placeholder="请选择数据库"
            @change="getStructure"
          >
            <el-option
              v-for="item in modelSubsList"
              :label="item.dictValue"
              :value="item.dictValue"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表名：" prop="tableName">
          <el-select
            v-model="form.tableName"
            clearable
            placeholder="请选择表名"
            @change="getFields"
          >
            <el-option
              v-for="(item, i) in structureList"
              :label="item"
              :value="item"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询字段：" prop="fields">
          <el-select
            v-model="form.fields"
            multiple
            collapse-tags
            clearable
            placeholder="请选择表名"
          >
            <el-option
              v-for="(item, i) in fieldsList"
              :label="item"
              :value="item"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 选择时间： -->
        <el-form-item label="选择时间：" prop="time" style="margin-right: 20px">
          <el-date-picker
            cellClassName="timeRangePicker"
            v-model="form.time"
            type="datetimerange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="终端号：" :prop="form.vehicleNo ? '' : 'deviceNo'">
          <el-input v-model="form.deviceNo" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="车架号：" :prop="form.deviceNo ? '' : 'vehicleNo'">
          <el-input v-model="form.vehicleNo" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            style="margin-right: 10px"
            >查询</el-button
          >
          <span>附件表终端号必须为12位，不足可在前补0</span>
        </el-form-item>
      </el-form>
    </div>
    <pl-table
      id="outputTable"
      :data="tableData"
      :stripe="true"
      :resizable="false"
      :height="tableHeight"
      :border="false"
      :highlight-current-row="false"
      :row-height="rowHeight"
      :excess-rows="3"
      auto-resize
      use-virtual
    >
      <pl-table-column
        v-if="tableData.length > 0"
        type="index"
        width="70"
        label="序号"
      ></pl-table-column>
      <pl-table-column
        v-if="tableData.length > 0"
        width="170"
        label="time"
        prop="time"
        :formatter="formatTime"
        :show-overflow-tooltip="true"
      >
      </pl-table-column>
      <pl-table-column
        v-for="(item, i) in header"
        :prop="item"
        :key="i"
        :label="item"
        :width="header.length > 7 ? '150px' : ''"
        :show-overflow-tooltip="true"
      ></pl-table-column>
    </pl-table>
    <div class="pagination" ref="pagination">
      <div class="el-pagination">
        <span class="el-pagination__total">共 {{ total }} 条</span>
      </div>
    </div>
    <!--提醒用户系统已更新 -->
    <el-dialog
      title="提醒内容"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <remind-update
        ref="remindUpdate"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></remind-update>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  formatDate,
  checkTimeLimitDay,
  getStartDate,
  getCurentDate,
  getCurent
} from '@/common/utils/index'
import { PlTable, PlTableColumn } from 'pl-table'
import {
  queryDictsByCodes,
  querDbStructure,
  querDbData,
} from '@/api/lib/api.js'
import getXLS from '@/common/utils/exportXLS'

import remindUpdate from './components/remindUpdate.vue'



export default {
  name: 'mocha',
  components: { PlTable, PlTableColumn, remindUpdate },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (new Date() < value[0] || new Date() < value[1]) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableHeight: 500,
      rowHeight: 50,
      total: 0,
      tableData: [],
      timeLimit: 60,
      form: {
        dataType: null,
        database: null,
        tableName: null,
        fields: [],
        time: [new Date(getStartDate()), new Date(getCurentDate())],
        deviceNo: null,
        vehicleNo: null,
        pageSize: 1000
      },
      trailTableData: [], // 表格
      trailTableDataBase: [], // 表格数据仓库
      tableLoading: false, // 表格加载
      tableTotal: 0, // 表格total
      trailTableStatus: false, // 表格正在加载
      loadMoreEnd: true, // 无限滚动是否结束
      queryStartTime: '', // 回放查询起始时间
      queryEndTime: '', // 回放查询结束时间
      isQueryEnd: false, // 回放查询结果是否小余100
      isLoadOver: false, // 回放是否可继续滚动加载
      changeTimeOver: true, // 切换时间时过程中不请求加载
      total: 0,
      page: 1,
      pageSize: 10,
      radio: '',
      timeArr: [],
      isFirstTimeLoading: true, //是否为第一次加载
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date() - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date() - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        dataType: [
          { required: true, message: '请选择数据库源', trigger: 'blur' }
        ],
        database: [
          { required: true, message: '请选择数据库', trigger: 'blur' }
        ],
        tableName: [{ required: true, message: '请选择表名', trigger: 'blur' }],
        fields: [
          {
            required: true,
            type: 'array',
            message: '请选择查询字段',
            trigger: 'change'
          }
        ],
        deviceNo: [
          { required: true, message: '请选择终端号', trigger: 'blur' }
        ],
        vehicleNo: [
          { required: true, message: '请选择车架号', trigger: 'blur' }
        ],

        time: [
          { required: true, message: '请选择日期', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ]
      },
      searchLoading: false,
      modelList: [],
      modelSubsList: [],
      structureList: [],
      fieldsList: [],
      header: [],
      dialogVisible: false,
      editLoading: false,

    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.mocha.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      const formHeight = this.$refs.form.clientHeight
      let tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16 -
        10
      let remainder = tableHeight % 100
      this.tableHeight = tableHeight - remainder
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.getListByField()
    },
    /** 导出 */
    getXLS () {
      getXLS('#outputTable', `终端属性-${getCurent()}`)
    },

    //获取数据
    getListByField () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          if (data.time) {
            data.beginTime = formatDate(this.form.time[0])
            data.endTime = formatDate(this.form.time[1])
          } else {
            data.beginTime = null
            data.endTime = null
          }
          data.fields = this.form.fields.join(',')
          delete data.time
          querDbData(data)
            .then((res) => {
              if (res.code === 1000) {
                this.header = [...this.form.fields]
                this.header.splice(0, 1)
                this.tableData = JSON.parse(res.data)
                this.total = this.tableData.length
              } else {
                this.tableData = []
                this.total = 0
                this.$message.error(res.msg)
              }

              this.searchLoading = false
            })
            .catch(() => {
              this.searchLoading = false
            })
        } else {
          this.searchLoading = false
        }
      })
    },
    /**获取数据库 */
    getSubs (value) {
      this.form.database = ''
      this.form.tableName = ''
      this.form.fields = []

      if (value) {
        queryDictsByCodes({
          parentCodes: value
        }).then((res) => {
          if (res.code === 1000) {
            this.modelSubsList = res.data[value]
          }
        })
      }
    },

    //获取表名
    getStructure (value) {
      this.form.fields = []
      this.form.tableName = ''
      let { database } = this.form
      if (value) {
        querDbStructure({ database, tableName: null }).then((res) => {
          if (res.code === 1000) {
            this.structureList = res.data
          }
        })
      }
    },
    //获取 查询字段
    getFields (value) {
      this.form.fields = []
      let { database, tableName } = this.form
      if (value) {
        querDbStructure({ database, tableName }).then((res) => {
          if (res.code === 1000) {
            this.fieldsList = ['time', ...res.data]
            this.form.fields = ['time', ...res.data]
          }
        })
      }
    },
    //时间格式
    formatTime (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : formatDate(cellValue)
    },
    //提醒用户系统已更新
    remindUpdate () {
      this.dialogVisible = true
    },
    //  保存
    onSave () {
      this.$refs.remindUpdate.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false
      this.editLoading = false
    },
    /** 获取数据字典 */
    getDicts () {
      queryDictsByCodes({
        parentCodes: 'SJY'
      }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.SJY
        }
      })
    },

  },
  created () {
    this.getDicts()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  watch: {
    trailTableDataBase (val) {
      if (this.isLoadOver) return
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.mocha {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-resourse {
  .mocha {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
<style>
body,
html {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
body ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.5);
}
</style>
