var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "mocha", staticClass: "mocha" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.remindUpdate }
            },
            [_vm._v("提醒用户系统已更新")]
          )
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area mocha-form" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "82px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据库源：", prop: "dataType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择数据库源" },
                      on: { change: _vm.getSubs },
                      model: {
                        value: _vm.form.dataType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "dataType", $$v)
                        },
                        expression: "form.dataType"
                      }
                    },
                    _vm._l(_vm.modelList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "数据库：", prop: "database" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择数据库" },
                      on: { change: _vm.getStructure },
                      model: {
                        value: _vm.form.database,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "database", $$v)
                        },
                        expression: "form.database"
                      }
                    },
                    _vm._l(_vm.modelSubsList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "表名：", prop: "tableName" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择表名" },
                      on: { change: _vm.getFields },
                      model: {
                        value: _vm.form.tableName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "tableName", $$v)
                        },
                        expression: "form.tableName"
                      }
                    },
                    _vm._l(_vm.structureList, function(item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "查询字段：", prop: "fields" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        placeholder: "请选择表名"
                      },
                      model: {
                        value: _vm.form.fields,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fields", $$v)
                        },
                        expression: "form.fields"
                      }
                    },
                    _vm._l(_vm.fieldsList, function(item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { label: "选择时间：", prop: "time" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      cellClassName: "timeRangePicker",
                      type: "datetimerange",
                      align: "right",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "终端号：",
                    prop: _vm.form.vehicleNo ? "" : "deviceNo"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.deviceNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "deviceNo", $$v)
                      },
                      expression: "form.deviceNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "车架号：",
                    prop: _vm.form.deviceNo ? "" : "vehicleNo"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.vehicleNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vehicleNo", $$v)
                      },
                      expression: "form.vehicleNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSearch()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c("span", [_vm._v("附件表终端号必须为12位，不足可在前补0")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pl-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: true,
            resizable: false,
            height: _vm.tableHeight,
            border: false,
            "highlight-current-row": false,
            "row-height": _vm.rowHeight,
            "excess-rows": 3,
            "auto-resize": "",
            "use-virtual": ""
          }
        },
        [
          _vm.tableData.length > 0
            ? _c("pl-table-column", {
                attrs: { type: "index", width: "70", label: "序号" }
              })
            : _vm._e(),
          _vm.tableData.length > 0
            ? _c("pl-table-column", {
                attrs: {
                  width: "170",
                  label: "time",
                  prop: "time",
                  formatter: _vm.formatTime,
                  "show-overflow-tooltip": true
                }
              })
            : _vm._e(),
          _vm._l(_vm.header, function(item, i) {
            return _c("pl-table-column", {
              key: i,
              attrs: {
                prop: item,
                label: item,
                width: _vm.header.length > 7 ? "150px" : "",
                "show-overflow-tooltip": true
              }
            })
          })
        ],
        2
      ),
      _c("div", { ref: "pagination", staticClass: "pagination" }, [
        _c("div", { staticClass: "el-pagination" }, [
          _c("span", { staticClass: "el-pagination__total" }, [
            _vm._v("共 " + _vm._s(_vm.total) + " 条")
          ])
        ])
      ]),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "提醒内容",
                visible: _vm.dialogVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("remind-update", {
                ref: "remindUpdate",
                on: { close: _vm.onDialogClose, onLoading: _vm.onLoading }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSave()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }