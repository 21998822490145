
<template>
  <div class="body add-access">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <!-- 选择时间： -->
      <el-form-item label="提醒时间：" prop="date">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="addForm.date"
          type="datetime"
          placeholder="请选择时间"
          disabled
          :picker-options="pickerOptions"
          format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="提醒类型:" prop="type">
        <el-select v-model="addForm.type" clearable placeholder="请选择线路">
          <el-option
            v-for="(item, key) in options"
            :key="key"
            :label="item"
            :value="key + 1"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提醒内容：" prop="message">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          v-model.trim="addForm.message"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  sendMessage
} from '@/api/lib/api.js'
import {
  formatDate,
} from '@/common/utils/index'
let Base64 = require('js-base64').Base64

export default {

  props: {

  },
  data () {
    //不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error("不能选择过去时间"));
      }
      else {
        callback();
      }
    };
    return {
      addForm: {
        message: '系统有更新，请刷新界面获取最新版本',
        type: 1,
        date: null,
      },
      rules: {
        message: [{ required: true, message: '请选择', trigger: 'change' }],
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        date: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      options: [
        '提醒并强制刷新',
        // '只是提醒',
      ],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },

    }
  },
  methods: {
    // 新增/修改车辆
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          let date = formatDate(this.addForm.date)
          date = date.substring(0, date.length - 3)
          let data = {
            message: Base64.encode(this.addForm.message),
            type: this.addForm.type,
            date: `${date}:00`
          }
          sendMessage(data).then(res => {
            if (res.code === 1000) {
              // this.$message.success(`提醒已下发将在${date}提醒用户`)
              this.$message.success(`提醒已下发`)
              this.$emit('close')
            } else {
              this.$message.error(res.msg)
            }
            this.$emit('onLoading', false)

          })
        }
      })
    },


  },
  created () {

  },
  mounted () {
    // this.addForm.date = new Date().valueOf() + 5 * 60 * 1000//5分钟后
    this.addForm.date = new Date()
  }
}
</script>

<style lang="scss" scoped>
.add-access {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
