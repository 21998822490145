var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-access" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "提醒时间：", prop: "date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  cellClassName: "timeRangePicker",
                  type: "datetime",
                  placeholder: "请选择时间",
                  disabled: "",
                  "picker-options": _vm.pickerOptions,
                  format: "yyyy-MM-dd HH:mm"
                },
                model: {
                  value: _vm.addForm.date,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "date", $$v)
                  },
                  expression: "addForm.date"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提醒类型:", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择线路" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                _vm._l(_vm.options, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: key + 1 }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提醒内容：", prop: "message" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6 },
                  maxlength: "200",
                  "show-word-limit": "",
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.addForm.message,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "message",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.message"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }